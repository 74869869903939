import React from 'react';
import IconButton from '@mui/material/IconButton';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import DeviceOrientation, { Orientation } from 'react-screen-orientation';
import { navigationIndicator } from '../../config/theme';

const styles = {
  arrowFormat: navigationIndicator,
  rotateRightArrowPor: {
    position: "absolute",
    left: '50%',
    top: '75%',
    display: 'flex',
    flexDirection: 'column',
    transform: 'translate(-50%, -50%)',
  },
  rotateRightTextPor: {
    position: 'absolute',
    height: '200px',
    color: 'white',
    writingMode: 'vertical-rl',
    textOrientation: 'mixed',
    fontSize: '24px',
    left: '50%',
    top: '195%',
    display: 'flex',
    flexDirection: 'column',
    transform: 'translate(-50%, -50%)',
  },
  rotateRightArrowLan: {
    position: 'absolute',
    left: '75%',
    top: '50%',
    display: 'flex',
    flexDirection: 'column',
    transform: 'translate(-50%, -50%)',
  },
  rotateRightTextLan: {
    position: 'absolute',
    color: 'white',
    width: '200px',
    writingMode: 'horizontal-tb',
    textOrientation: 'mixed',
    fontSize: '24px',
    left: '195%',
    top: '50%',
    display: 'flex',
    flexDirection: 'column',
    transform: 'translate(-50%, -50%)',
  },
};

class SpinRightArrow extends React.Component{

  render() {
    return (
      <>
        <DeviceOrientation lockOrientation={'landscape'}>
          <Orientation orientation='landscape' alwaysRender={false}>
            <IconButton
              sx={{ ...styles.arrowFormat, ...styles.rotateRightArrowLan }}
              disabled
              size="large">
              <ArrowRightIcon fontSize="large" style={{fill: "white", transform: "scale(1.5)"}}/>
              <div style={styles.rotateRightTextLan}>Pan right</div>
            </IconButton>
          </Orientation>
          <Orientation orientation='portrait' alwaysRender={false}>
            <IconButton
              sx={{ ...styles.arrowFormat, ...styles.rotateRightArrowPor }}
              disabled
              size="large">
              <ArrowDropDownIcon fontSize="large" style={{fill: "white", transform: "scale(1.5)"}}/>
              <div style={styles.rotateRightTextPor}>Pan right</div>
              </IconButton>
          </Orientation>
        </DeviceOrientation>
      </>
    );
  }
}

export default SpinRightArrow;
