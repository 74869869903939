import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import DeviceOrientation, { Orientation } from 'react-screen-orientation';

const styles = {
  box: {
    maxWidth: '350px',
    maxHeight: '200px',
    position: 'absolute',
    padding: '5px 10px 5px 10px',
    display: 'flex',
    flexDirection: 'column',
    zIndex: 1,
  },
  landscapePosition: {
    transform: 'translate(-50%, -50%)',
  },
  portraitPosition: {
    transform: 'translate(-50%, -50%) rotate(90deg)',
  },
};

class CustomisedBox extends React.Component {

  reverseLRValueInLandscape = () => {
    if (this.props.left === '50%'){
      return this.props.left;
    } else {
      let newValue = 100 - parseInt(this.props.left, 10)
      return newValue + '%';
    }
  }

  render() {
    const { bgcolor, borderRadius, boxShadow, children, color, left, minHeight, minWidth, top } = this.props;
    return (
      <DeviceOrientation lockOrientation={'landscape'}>
        <Orientation orientation='landscape' alwaysRender={false}>
          <Box
            boxShadow={boxShadow || 4}
            minWidth={minWidth || '300px'}
            minHeight={minHeight || '105px'}
            top={ this.reverseLRValueInLandscape() || '50%'}
            left={top || '50%'}
            color={color || 'white'}
            bgcolor={bgcolor || 'rgba(0,0,0,0.5)'}
            sx={{ ...styles.box, ...styles.landscapePosition, borderRadius: borderRadius || 'borderRadius'}}
          >
            {children}
          </Box>
        </Orientation>
        <Orientation orientation='portrait' alwaysRender={false}>
          <Box
            boxShadow={boxShadow || 4}
            minWidth={minWidth || '300px'}
            minHeight={minHeight || '105px'}
            left={left || '50%'}
            top={top || '50%'}
            color={color || 'white'}
            bgcolor={bgcolor || 'rgba(0,0,0,0.5)'}
            sx={{ ...styles.box, ...styles.portraitPosition, borderRadius: borderRadius || 'borderRadius'}}
          >
            {children}
          </Box>
        </Orientation>
      </DeviceOrientation>
    );
  }
}

CustomisedBox.propTypes = {
  boxShadow: PropTypes.number,
  minWidth: PropTypes.string,
  minHeight: PropTypes.string,
  top: PropTypes.string,
  left: PropTypes.string,
  color: PropTypes.string,
  bgcolor: PropTypes.string,
  borderRadius: PropTypes.string
};

export default CustomisedBox;
