import React from 'react';
import CircularProgress from '@mui/material/CircularProgress/CircularProgress';
import DeviceOrientation, { Orientation } from 'react-screen-orientation';

const styles = {
    circularProgressBarBase: {
        position: 'absolute',
        left: '50%',
        top: '50%',
        display: 'flex',
        flexDirection: 'column',
        zIndex: 1,
        transform: 'translate(-50%, -50%) rotate(90deg)',
    },
    circularProgressBarBaseLandscape: {
        position: 'absolute',
        left: '50%',
        top: '50%',
        zIndex: 1,
        display: 'flex',
        flexDirection: 'column',
        transform: 'translate(-50%, -50%)',
    },
}

class CircularProgressBarBase extends React.Component{

    render() {
        return (
            <DeviceOrientation lockOrientation={'landscape'}>
                <Orientation orientation='landscape' alwaysRender={false}>
                    <div style={styles.circularProgressBarBaseLandscape}>
                        <CircularProgress variant="determinate" value={100} thickness={2} size="3.8rem" style={{color: "white", opacity:0.6}}/>
                    </div>
                </Orientation>
                <Orientation orientation='portrait' alwaysRender={false}>
                    <div style={styles.circularProgressBarBase}>
                        <CircularProgress variant="determinate" value={100} thickness={2} size="3.8rem" style={{color: "white", opacity:0.6}}/>
                    </div>
                </Orientation>
            </DeviceOrientation>
        );
    }
}

export default CircularProgressBarBase;
