import React from 'react';
import IconButton from '@mui/material/IconButton';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import DeviceOrientation, { Orientation } from 'react-screen-orientation';
import { navigationIndicator } from '../../config/theme';

const styles = {
  arrowFormat: navigationIndicator,
  tiltDownArrowPor: {
    position: 'absolute',
    left: '25%',
    top: '50%',
    display: 'flex',
    flexDirection: 'column',
    transform: 'translate(-50%, -50%)',
  },
  tiltDownTextPor: {
    position: 'absolute',
    height: '200px',
    color: 'white',
    writingMode: 'vertical-rl',
    textOrientation: 'mixed',
    fontSize: '24px',
    left: '-35%',
    top: '50%',
    display: 'flex',
    flexDirection: 'column',
    transform: 'translate(-50%, -50%)',
  },
  tiltDownArrowLan: {
    position: "absolute",
    left: '50%',
    top: '75%',
    display: 'flex',
    flexDirection: 'column',
    transform: 'translate(-50%, -50%)',
  },
  tiltDownTextLan: {
    position: 'absolute',
    color: 'white',
    width: '200px',
    writingMode: 'horizontal-tb',
    textOrientation: 'mixed',
    fontSize: '24px',
    left: '50%',
    top: '130%',
    display: 'flex',
    flexDirection: 'column',
    transform: 'translate(-50%, -50%)',
  },
};

class InclinationDownArrow extends React.Component{

  render() {
      return (
        <>
          <DeviceOrientation lockOrientation={'landscape'}>
            <Orientation orientation='landscape' alwaysRender={false}>
              <IconButton
                sx={{ ...styles.arrowFormat, ...styles.tiltDownArrowLan }}
                disabled
                size="large">
                <ArrowDropDownIcon fontSize="large" style={{fill: "white", transform: "scale(1.5)"}}/>
                <div style={styles.tiltDownTextLan}>Tilt Down</div>
              </IconButton>
            </Orientation>
            <Orientation orientation='portrait' alwaysRender={false}>
              <IconButton
                sx={{ ...styles.arrowFormat, ...styles.tiltDownArrowPor }}
                disabled
                size="large">
                <ArrowLeftIcon fontSize="large" style={{fill: "white", transform: "scale(1.5)"}}/>
                <div style={styles.tiltDownTextPor}>Tilt Down</div>
              </IconButton>
            </Orientation>
          </DeviceOrientation>
      </>
    );
  }
}

export default InclinationDownArrow;
