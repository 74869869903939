import React from 'react';
import CameraWebRTC from '../components/CameraWebRTC';
import NavigationCluster from '../components/NavigationCluster';
import Routing from '../utils/Routing';

class Camera extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      videoContainer: undefined,
    }
  }

  state = {
    videoContainer: undefined
  }

  componentDidMount() {
    this.setState({
      videoContainer: this.videoContainer
    });
  }

  render() {
    const state = this.props.location?.state;
    const { navigate } = this.props;
    const acceptedOnBoarding = state?.acceptedOnboarding;

    return (
      <>
        {!acceptedOnBoarding &&
          <>{navigate(Routing.APP_LANDING)}</>
        }
        {acceptedOnBoarding &&
          <>
            <div style={{zIndex: -9999}}>
              <CameraWebRTC navigate={navigate} ref={(container) => this.videoContainer = container}/>
            </div>
            <div style={{zIndex: 0}}>
              <NavigationCluster navigate={navigate} videoContainer={this.state.videoContainer}/>
            </div>
          </>
        }
      </>
    );
  }
}

export default Camera;
