import React from 'react'
import { Box, Button } from '@mui/material';

const styles = {
  div: {
    textAlign: 'center',
    justifyContent: 'center',
    padding: 1,
  },
  navButton: {
    backgroundColor: '#1f6ee1',
    width: '252px',
    maxWidth: '100%',
    height: '48px',
    borderRadius: '9px',
    textTransform: 'initial',
    fontSize: '16px',
  }
};

class StartButton extends React.Component {
  render() {
    const { handleClick } = this.props;

    return (
      <Box sx={styles.div}>
        <Button sx={styles.navButton}
                variant={this.props.variant || 'contained'}
                color={this.props.color || 'primary'}
                onClick={handleClick}
        >{this.props.label}</Button>
      </Box>
    )
  }
}

export default StartButton;
