import NetworkService from './NetworkService';

export default class VerifyService {

    static async verifySession(examSlotId, passcode, abortSignal){
        const response = await NetworkService.get(`/mert/login/${examSlotId}?passcode=${passcode}`, abortSignal);
        return response;
    }

    /**
     * Ensure conditions for which type of login to use aren't pre-built into the application
     */
    static getDemoModeToggle() {
        return process.env.REACT_APP_APPCONFIG_DEMO;
    }
}