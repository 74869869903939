import React from 'react'
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';

const styles = {
  div: {
    textAlign: 'center',
    justifyContent: 'center',
    padding: 1,
  },
  navButton: {
    width: 'calc(90%)',
    textTransform: 'initial',
    fontSize: '16px',
  },
  previousButton: {
    color: 'grey[700]',
    textTransform: 'initial',
  },
  nextButton: {
    textTransform: 'initial',
  }
};

class NavigationButton extends React.Component{
  render(){
    const { handleClick, color, label, styleType, to, variant } = this.props;

    return (
      <>
        {(styleType === "FILL_CENTERED") &&
          <div style={styles.div}>
            <Button sx={styles.navButton}
              variant= {variant || "contained"}
              color={color || "primary"}
              component={Link}
              to={to}
              onClick={handleClick}
            >{label}</Button>
          </div>
        }
        {(styleType === "SEPARATED") &&
          <Button sx={color === 'default' ? styles.previousButton : styles.nextButton}
            variant={variant || "contained"}
            color={color || "primary"}
            component={Link}
            to={to}
            onClick={handleClick}
          >{label}</Button>
        }
      </>
    )
  }
}

NavigationButton.propTypes = {
  handleClick: PropTypes.func,
  color: PropTypes.string,
  label: PropTypes.string.isRequired,
  styleType: PropTypes.string,
  to: PropTypes.string.isRequired,
  variant: PropTypes.string,
};

export default NavigationButton;
