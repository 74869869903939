import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import CircleFocus from './CircleFocus';
import CircularProgressBarUpdate from './CircularProgressBar';
import CircularProgressBarBase from './CircularProgressBarBase';
import CustomisedBox from '../CustomisedBox';
import * as params from '../../utils/PhotoTimeoutParameters.js'

const styles = {
  imageSensor: {
    display: 'none',
    zIndex: 1,
  },
  textInstruction: {
    textAlign: 'center',
    color: 'white',
    paddingTop: '3px',
    fontSize: '20px',
    fontWeight: 'bold',
  },
  onFlashPhotoContainer: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    backgroundColor: 'black',
    opacity: 1,
  }
}

class AutoPhoto extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      time: params.STARTTIME,
      countdownStarted: false,
    }

    this.internalState = {
      timeInterval: params.COUNTDOWN_INTERVAL,
      photoTaken: false,
      timer: null,
      photoCounter: 0
    }

    this.startCountdown = this.startCountdown.bind(this);
    this.countDown = this.countDown.bind(this);
    this.firstFlashRef = React.createRef();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.readyForPhoto && this.internalState.photoTaken) {
      this.internalState.photoTaken = false;
    }
  }

  uriToImage = async (uri) => {
    return fetch(uri)
      .then(res => res.blob())
      .then(blob => {
        return new File([blob], `image${this.props.photoCount}.jpg`, { type: "image/jpg" });
      })
      .catch((error) => {
        alert("Something went wrong while attempting to capture image.");
      });
  }

  startCountdown() {
    if (!this.state.countDownStarted) {
      this.setState({ countDownStarted: true, time: params.STARTTIME });
      this.internalState.timer = setInterval(this.countDown, this.internalState.timeInterval);
    }
  }

  countDown() {
    if (!this.props.readyForPhoto) {
      clearInterval(this.internalState.timer);
      this.setState({ countDownStarted: false });
    } else {
      if (this.state.time < this.internalState.timeInterval / 1000 || this.state.time === 0) {
        clearInterval(this.internalState.timer);
        this.setState({ countDownStarted: false });
        this.takePhoto();
        this.updatePhotoCounter();
      } else {
        const newTime = this.state.time - this.internalState.timeInterval / 1000;
        this.setState({
          time: newTime,
        });
      }
    }
  }

  takePhoto = () => {
    if (this.internalState.photoTaken || !this.props.readyForPhoto) {
      return;
    } else {
      this.internalState.photoTaken = true;
    }

    const context = this.canvas.getContext('2d');
    const videoElement = this.props.videoContainer.video;

    this.canvas.width = videoElement.videoWidth;
    this.canvas.height = videoElement.videoHeight;

    context.drawImage(videoElement, 0, 0);

    let coords = this.props.captureCallback();

    if (coords != null && this.canvas != null) {
      this.triggerPhotoFlash();
      this.uriToImage(this.canvas.toDataURL('image/jpeg'))
        .then(file => {
          this.props.photoConsumerCallback(file, coords.alpha, coords.beta, coords.gamma);
        }).catch((_error) => {
          alert("Something went wrong while attempting to capture image.");
        });
    } else {
      console.warn('Error: no photo to save, skipping.')
    }
  }

  triggerPhotoFlash = () => {
    this.toggleFlashVisibility();
    setTimeout(this.toggleFlashVisibility, params.FLASH_TIMEOUT);
  }

  toggleFlashVisibility = () => {
    if (this.firstFlashRef.current != null) {
      if (this.firstFlashRef.current.style.visibility === "hidden") {
        this.firstFlashRef.current.style.visibility = "visible";
      } else {
        this.firstFlashRef.current.style.visibility = "hidden";
      }
    }
  }

  updatePhotoCounter = () => {
    this.internalState.photoCounter++;
  }

  render() {
    const { photoCount, totalPhotos, readyForPhoto } = this.props;
    const { countDownStarted } = this.state;

    let isRoomScanToContinue = photoCount === 0 || this.internalState.photoCounter < totalPhotos

    if (readyForPhoto && !countDownStarted && isRoomScanToContinue) {
      this.startCountdown();
    }

    return (
      <>
        {isRoomScanToContinue &&
          <>
            <CircularProgressBarBase />
            <CircleFocus readyForPhoto={readyForPhoto} />
            {/* Visibility hidden must be in the style prop, not sx, so that it can be modified by the flash */}
            <Box ref={this.firstFlashRef} sx={{ ...styles.onFlashPhotoContainer, zIndex: 99999 }} style={{ visibility: 'hidden' }}></Box>
          </>
        }

        {countDownStarted && readyForPhoto && isRoomScanToContinue &&
          <>
            <CircularProgressBarUpdate />
            <CustomisedBox minHeight="35px" left="20%" borderRadius="25px">
              <Box sx={styles.textInstruction}>Hold still</Box>
            </CustomisedBox>
          </>
        }

        <div style={{ position: "absolute" }}>
          <canvas ref={(canvas) => this.canvas = canvas} style={styles.imageSensor} />
        </div>
      </>
    );
  }
}

AutoPhoto.propTypes = {
  readyForPhoto: PropTypes.bool.isRequired,
  photoCount: PropTypes.number.isRequired,
  totalPhotos: PropTypes.number.isRequired,
  videoContainer: PropTypes.object,
  captureCallback: PropTypes.func.isRequired,
  photoConsumerCallback: PropTypes.func.isRequired,
}

export default AutoPhoto;
