import React from 'react';
import PropTypes from 'prop-types';
import DeviceOrientation, { Orientation } from 'react-screen-orientation';
import * as d3 from 'd3';
import * as params from '../../utils/PhotoTimeoutParameters.js'

const styles = {
    landscapePosition: {
        position: 'absolute',
        width: '100px',
        height: '100px',
        left: '50%',
        top: '50%',
        display: 'flex',
        flexDirection: 'column',
        zIndex: 1,
        transform: 'translate(-50%, -50%)',
    },
    portraitPosition: {
        position: 'absolute',
        width: '100px',
        height: '100px',
        left: '50%',
        top: '50%',
        display: 'flex',
        flexDirection: 'column',
        zIndex: 1,
        transform: 'translate(-50%, -50%) rotate(90deg)',
    }
}

class CircleFocus extends React.Component{
    constructor(props) {
        super(props);

        this.circleRef = React.createRef();
    }

    componentDidUpdate() {
        if (this.props.readyForPhoto){
            d3.select(this.circleRef.current)
                .transition()
                .duration(params.CIRCLEFOCUS_DURATION)
                .ease(d3.easeLinear)
                .attr("r", 6.6);
        } else {
            d3.select(this.circleRef.current)
                .transition()
                .duration(params.CIRCLEFOCUS_DURATION)
                .ease(d3.easeLinear)
                .attr("r", 15);
        }
    }

    render() {
        return (
            <DeviceOrientation lockOrientation={'landscape'}>
                <Orientation orientation='landscape' alwaysRender={false}>
                    <div style={styles.landscapePosition}>
                        <svg >
                            <circle ref={this.circleRef} cx={50} cy={50} r={15} stroke="white" strokeWidth="2.5px"
                                    fill="transparent"/>
                        </svg>
                    </div>
                </Orientation>
                <Orientation orientation='portrait' alwaysRender={false}>
                    <div style={styles.portraitPosition}>
                        <svg >
                            <circle ref={this.circleRef} cx={50} cy={50} r={15} stroke="white" strokeWidth="2.5px"
                                    fill="transparent"/>
                        </svg>
                    </div>
                </Orientation>
            </DeviceOrientation>
        );
    }
}

CircleFocus.propTypes = {
    readyForPhoto: PropTypes.bool,
}

export default CircleFocus;
