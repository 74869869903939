export default class NetworkService {
    //TODO: Will need to change based off back-end API

    /**
     * @param {Response} resp
     * @param useCustomError
     * @param type
     */
    static async handleStatusCode(resp, useCustomError = false, type ='application/json') {
        if (resp.status >= 400 && resp.status <= 599) {
            const actualResp = type === 'application/json' ? await resp.json() : await resp.text();
            console.error('Error: ', actualResp);

            let errorMessage = '';
            switch (resp.status) {
                case 400:
                    errorMessage = useCustomError ? actualResp.message : 'Sorry, the request was incorrect or corrupted. Please check the submission and try again.';
                    break;
                case 401:
                    errorMessage = 'Sorry, there is an error with your session. Please try logging in again.';
                    break;
                case 403:
                    errorMessage = 'Sorry, this information is restricted.';
                    break;
                case 404:
                    errorMessage = 'Sorry, we cannot find the information you are looking for.';
                    break;
                default: // 5xxs and 429s go here
                    errorMessage = 'Sorry, the system had an issue with processing the request. Please try again.';
                    break;
            }
            console.error(`${resp.status}: ${errorMessage}`);
            throw new Error(`${errorMessage}`);
        }
        return resp;
    }

    static get(route, abortSignal, type = 'application/json') {
        return fetch(process.env.REACT_APP_APPCONFIG_BACKEND_URL + route, {
            method: "GET",
            headers: {
                Accept: type,
            },
            signal: abortSignal,
        }).then(resp => this.handleStatusCode(resp, false, type));
    }

    static post(route, payload, type = "application/json") {
        //TODO: Temporary check for XSRF-TOKEN. Will be removed once authentication flow is properly implemented.
        const body = type === "multipart/form-data" ? payload : JSON.stringify(payload);
        return fetch(process.env.REACT_APP_APPCONFIG_BACKEND_URL + route, {
            method: "POST",
            body: body,
        }).then(resp => this.handleStatusCode(resp, false, type));
    }


}