import React from 'react';
import Box from '@mui/material/Box';
import DeviceOrientation, { Orientation } from 'react-screen-orientation';

const styles = {
    box: {
        minWidth: '300px',
        maxWidth: '350px',
        minHeight: '105px',
        maxHeight: '200px',
        position: 'absolute',
        left: '50%',
        top: '50%',
        backgroundColor: 'secondary.contrastText',
        padding: '5px 10px 5px 10px',
        display: 'flex',
        flexDirection: 'column',
        zIndex: 999,
    },
    landscapePosition: {
        transform: 'translate(-50%, -50%)',
    },
    portraitPosition: {
        transform: 'translate(-50%, -50%) rotate(90deg)',
    }

};

class CenteredBox extends React.Component {
    render() {
        return (
            <DeviceOrientation lockOrientation={'landscape'}>
                <Orientation orientation='landscape' alwaysRender={false}>
                    <Box boxShadow={4} sx={{ ...styles.box, ...styles.landscapePosition }}>
                        {this.props.children}
                    </Box>
                </Orientation>
                <Orientation orientation='portrait' alwaysRender={false}>
                    <Box boxShadow={4} sx={{ ...styles.box, ...styles.portraitPosition }}>
                        {this.props.children}
                    </Box>
                </Orientation>
            </DeviceOrientation>
        )
    }
}

export default CenteredBox;
