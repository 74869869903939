import React from 'react';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import Routing from '../utils/Routing';
import NavigationButton from '../components/NavigationButton';
import dot2Image from '../assets/dot2nd.svg';
import MERTonboard2Image from '../assets/MERTonboard2.gif'

class OnBoarding1 extends React.Component {
  state = {
    displayAnimation: true,
  }

  hideAnimation = () => {
    this.setState({displayAnimation: false})
  }

  render() {
    const { displayAnimation } = this.state;

    return (
      <>
        {displayAnimation &&
          <Tooltip
            enterTouchDelay={0}
            placement="right"
            componentsProps={{
              tooltip: {
                sx: {
                  backgroundColor: 'transparent',
                }
              }
            }}
            title={<Button sx={{textTransform: 'initial'}} onClick={this.hideAnimation}>Tap to hide animation</Button>}
          >
            <img
              src={MERTonboard2Image}
              style={{display: 'block', margin: '0 auto', width: 'auto', height: '100%', maxHeight: '30vh', maxWidth: "calc(100%)"}}
              alt="Animation first showing a camera held at 90 degrees to the horizontal facing forward. The camera is tilted upwards 30 degrees above horizontal, then tilted downwards 30 degrees below horizontal."
            />
          </Tooltip>
        }
        <Typography variant='h2'>Three Photo Angles</Typography>
        <Typography variant='body1'>You need to take photos at three different angles:</Typography>

        <Typography variant="body1" component="ol">
          <li>Straight Ahead</li>
          <li>Tilt up 30°</li>
          <li>Tilt down 30°</li>
        </Typography>

        <img src={dot2Image} style={{display: 'block', margin: '1em auto'}} alt="Step two of three"/>

        <div style={{display: 'flex', justifyContent: 'space-between'}}>
          <NavigationButton variant='text' styleType="SEPARATED" onClick to={Routing.path()} label="Previous"/>
          <NavigationButton onClick styleType="SEPARATED" variant='text' to={Routing.path(Routing.BOARDING2)} label="Next"/>
        </div>

      </>
    );
  }
}

export default OnBoarding1;
