import React from 'react';
import DeviceOrientation, { Orientation } from 'react-screen-orientation';
import RotatePhoneSvg from '../../assets/RotatePhoneLeft';

const styles = {
    rotateFormat: {
        width: '60px',
        height: '60px',
        transform: 'scaleX(-1) rotate(90deg)',
    },
    rotateRightPor: {
        position: 'absolute',
        left: '75%',
        top: '50%',
        display: 'flex',
        flexDirection: 'column',
        transform: 'translate(-50%, -50%)',
    },
    rotateRightTextPor: {
        position: 'absolute',
        height: '200px',
        color: 'white',
        writingMode: 'vertical-rl',
        textOrientation: 'mixed',
        fontSize: '24px',
        left: '135%',
        top: '120%',
        display: 'flex',
        flexDirection: 'column',
        transform: 'translate(-50%, -50%)',
    },
    rotateRightLan: {
        position: 'absolute',
        left: '50%',
        top: '25%',
        display: 'flex',
        flexDirection: 'column',
        transform: 'translate(-50%, -50%)',
    },
    rotateRightTextLan: {
        position: 'absolute',
        color: 'white',
        width: '200px',
        writingMode: 'horizontal-tb',
        textOrientation: 'mixed',
        fontSize: '24px',
        left: '108%',
        top: '-30%',
        display: 'flex',
        flexDirection: 'column',
        transform: 'translate(-50%, -50%)',
    },
}

class RotateRightArrow extends React.Component{

    render() {
        return (
            <>
                <DeviceOrientation lockOrientation={'landscape'}>
                    <Orientation orientation='landscape' alwaysRender={false}>
                        <div style={styles.rotateRightLan}>
                            <div style={styles.rotateFormat}>
                                <RotatePhoneSvg/>
                            </div>
                            <div style={styles.rotateRightTextLan}>Rotate Right</div>
                        </div>
                    </Orientation>
                    <Orientation orientation='portrait' alwaysRender={false}>
                        <div style={styles.rotateRightPor}>
                            <div style={styles.rotateFormat}>
                                <RotatePhoneSvg/>
                            </div>
                            <div style={styles.rotateRightTextPor}>Rotate Right</div>
                        </div>
                    </Orientation>
                </DeviceOrientation>
            </>

        )
    }
}

export default RotateRightArrow;
