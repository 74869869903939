import React from "react";
import {SPIN_LEFT, SPIN_RIGHT} from "../../phone-orientation/VectorCalculatorConstants";
import SpinRightArrow from "./SpinRightArrow";
import SpinLeftArrow from "./SpinLeftArrow";

class Spin extends React.Component {
    render() {
        let spinRight = this.props.nextDirection === SPIN_RIGHT;
        let spinLeft = this.props.nextDirection === SPIN_LEFT;
        return(
            <div>
                {spinRight && <SpinRightArrow/>}
                {spinLeft && <SpinLeftArrow/>}
            </div>
        )
    }
}

export default Spin;