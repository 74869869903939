import React, { Component } from 'react';
import QueryString from 'query-string'
// Import webRTC shim methods for browser support
// eslint-disable-next-line
import adapter from 'webrtc-adapter';
import { AuthProvider } from '../authContext';
import Routing from '../utils/Routing';
import VerifyService from '../services/VerifyService';
import { COOKIES_DISABLED } from '../constants/PermissionsError';
import { EXAM_SLOT_ID, PASSCODE, REDIRECT_PATH } from '../constants/SessionStorageVariables';
import { LOGIN_API, NO_ACCESS, DEMO } from '../constants/login';

class Auth extends Component {

  state = {
    authorised: false,
    isPending: true,
    isErrored: false,
    errorType: undefined,
    examSlotId: undefined,
    passcode: undefined,
  }

  //TODO: Use abort-controller-polyfill library for extended compatibility rather than this temporary fix.
  controller = window.AbortController ? new AbortController() : undefined;

  hasCookies = () => {
    try {
      sessionStorage.setItem("compatibilityTest", "test");
      sessionStorage.getItem("compatibilityTest");
      sessionStorage.removeItem("compatibilityTest");
      return true;
    } catch (err) {
      return false;
    }
  }

  isCompatible = () => {
    try {
      //Checking browser compatibility
      return (navigator.getUserMedia || (navigator.mediaDevices && navigator.mediaDevices.getUserMedia))
        && window.DeviceMotionEvent && !(/micromessenger/i.test(navigator.userAgent));
    } catch (err) {
      return false;
    }
  }

  parseURLPath = (path, queryString) => {
    let examSlotId;
    let passcode;

    if (!sessionStorage.getItem(EXAM_SLOT_ID) || !sessionStorage.getItem(PASSCODE)) {
      if (VerifyService.getDemoModeToggle() === DEMO) {
        examSlotId = "dummyExamSlotId";
        passcode = "dummyPasscode";
      } else {
        examSlotId = path.split("/").slice(-1)[0];
        passcode = QueryString.parse(queryString.slice(1)).passcode;
      }

      if (!examSlotId || !passcode) {
        throw new Error("Unable to obtain exam slot id or passcode from URL");
      }

      sessionStorage.setItem(EXAM_SLOT_ID, examSlotId);
      sessionStorage.setItem(PASSCODE, passcode); //Slice to account for ? at start of string
    } else {
      examSlotId = sessionStorage.getItem(EXAM_SLOT_ID);
      passcode = sessionStorage.getItem(PASSCODE);
    }
    return { examSlotId: examSlotId, passcode: passcode };
  }

  initiateLogin = (path, queryString) => {
    //TODO: Fix me! Error handling needs a refactor and this is very inelegant. (Pushing to home page even though it's errored)
    if (!this.isCompatible()) {
      this.setState({ isErrored: false, authorised: true, isPending: false });
      return this.props.navigate(Routing.path());
    }
    if (!this.hasCookies()) return this.setState({ isErrored: true, isPending: false, errorType: COOKIES_DISABLED });

    let sessionInformation;
    try {
      sessionInformation = this.parseURLPath(path, queryString);
    } catch (err) {
      return this.setState({ isErrored: true, isPending: false, errorType: NO_ACCESS })
    }

    if (process.env.REACT_APP_AUTH_METHOD === LOGIN_API && VerifyService.getDemoModeToggle() !== DEMO) {
      this.checkSession(sessionInformation.examSlotId, sessionInformation.passcode);
    } else {
      this.setState({
        authorised: true,
        isPending: false,
        examSlotId: sessionInformation.examSlotId,
        passcode: sessionInformation.passcode,
      });
      //Saving the desired redirect page for the user to go to if they refresh.
      const redirectPath = sessionStorage.getItem(REDIRECT_PATH) ? sessionStorage.getItem(REDIRECT_PATH) : undefined;
      if (window.location.pathname !== Routing.path(redirectPath)) this.props.navigate(Routing.path(redirectPath));
    }
  };

  checkSession = async (examSlotId, passcode) => {
    try {
      //TODO: Possibly utilise XSRF token instead in future.
      if (!passcode || !examSlotId) throw new Error("Invalid exam slot Id or passcode");

      await VerifyService.verifySession(examSlotId, passcode, this.controller.signal || undefined);

      this.setState({
        authorised: true,
        isPending: false,
        examSlotId: examSlotId,
        passcode: passcode,
      });

      /*TODO: Possibly change the overall flow for redirects to make it more user-friendly. (Currently will always
       * redirect to either onboarding (home) or app landing page)
      */
      const redirectPath = sessionStorage.getItem(REDIRECT_PATH) ? sessionStorage.getItem(REDIRECT_PATH) : undefined;
      if (window.location.pathname !== Routing.path(redirectPath)) this.props.navigate(Routing.path(redirectPath));

    } catch (err) {
      sessionStorage.clear();
      this.setState({
        authorised: false,
        isPending: false,
        isErrored: true,
        errorType: NO_ACCESS,
      });
    }
  }

  render() {
    const authProviderValue = {
      ...this.state,
      initiateLogin: this.initiateLogin,
      checkSession: this.checkSession,
    };
    return (
      <AuthProvider value={authProviderValue}>
        {this.props.children}
      </AuthProvider>
    );
  }
}

export default Auth;
