import React from 'react';
import IconButton from '@mui/material/IconButton';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import DeviceOrientation, { Orientation } from 'react-screen-orientation';
import { navigationIndicator } from '../../config/theme';

const styles = {
  arrowFormat: navigationIndicator,
  tiltUpArrowPor: {
    position: 'absolute',
    left: '75%',
    top: '50%',
    display: 'flex',
    flexDirection: 'column',
    transform: 'translate(-50%, -50%)',
  },
  tiltUpTextPor: {
    position: 'absolute',
    height: '200px',
    color: 'white',
    writingMode: 'vertical-rl',
    textOrientation: 'mixed',
    fontSize: '24px',
    left: '135%',
    top: '50%',
    display: 'flex',
    flexDirection: 'column',
    transform: 'translate(-50%, -50%)',
  },
  tiltUpArrowLan: {
    position: 'absolute',
    left: '50%',
    top: '25%',
    display: 'flex',
    flexDirection: 'column',
    transform: 'translate(-50%, -50%)',
  },
  tiltUpTextLan: {
    position: 'absolute',
    color: 'white',
    width: '200px',
    writingMode: 'horizontal-tb',
    textOrientation: 'mixed',
    fontSize: '24px',
    left: '50%',
    top: '-30%',
    display: 'flex',
    flexDirection: 'column',
    transform: 'translate(-50%, -50%)',
  },
};

class InclinationUpArrow extends React.Component{

  render() {
      return (
        <>
          <DeviceOrientation lockOrientation={'landscape'}>
            <Orientation orientation='landscape' alwaysRender={false}>
              <IconButton
                sx={{ ...styles.arrowFormat, ...styles.tiltUpArrowLan }}
                disabled
                size="large">
                <ArrowDropUpIcon fontSize="large" style={{fill: "white", transform: "scale(1.5)"}}/>
                <div style={styles.tiltUpTextLan}>Tilt Up</div>
              </IconButton>
            </Orientation>
            <Orientation orientation='portrait' alwaysRender={false}>
              <IconButton
                sx={{ ...styles.arrowFormat, ...styles.tiltUpArrowPor }}
                disabled
                size="large">
                <ArrowRightIcon fontSize="large" style={{fill: "white", transform: "scale(1.5)"}}/>
                <div style={styles.tiltUpTextPor}>Tilt Up</div>
              </IconButton>
            </Orientation>
          </DeviceOrientation>
      </>
    );
  }
}

export default InclinationUpArrow;
