import { createContext } from 'react'

export const authContext = createContext({
        authorised: false,
        examSlotId: undefined,
        passcode: undefined,
        isPending: true,
        isErrored: false,
        errorType: undefined,
        initiateLogin: () => {},
        checkSession: () => {},
})

export const AuthProvider = authContext.Provider;
export const AuthConsumer = authContext.Consumer