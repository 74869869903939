import React from 'react';
import SvgMonashLogoMono from './MonashLogoImage';

const styles = {
  monashBrandHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '0.5em',
  },
  hidden: {
    position: 'absolute',
    left: '-10000px',
    top: 'auto',
    width: '1px',
    height: '1px',
    overflow: 'hidden',
  },
};

class MonashLogo extends React.Component {
  render() {
    return (
      <div style={styles.monashBrandHeader}>
        <a href="https://monash.edu/">
          <SvgMonashLogoMono />
          <span style={styles.hidden}>Monash University</span>
        </a>
      </div>
    );
  }
}

export default MonashLogo;
