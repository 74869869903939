import React from 'react';
import Typography from '@mui/material/Typography';
import NavigationButton from '../components/NavigationButton';
import dot3Image from '../assets/dot3rd.svg';
import animation from '../assets/2d-anim-v3-compressed1M.mp4';
import Routing from '../utils/Routing';

class OnBoarding2 extends React.Component {

  state = {
    animationPlaying: true,
  }

  toggleAnimation = () => {
    const { animationPlaying } = this.state;
    animationPlaying ? this.animation.pause() : this.animation.play();
    this.setState({animationPlaying: !animationPlaying});
  }
  render() {
    return (
      <>
        <video
          ref={(animation) => { this.animation = animation; }}
          src={animation}
          autoPlay={true}
          disableRemotePlayback={true}
          playsInline={true}
          loop
          style={{display: 'block', margin: '0 auto', width: '100%', height: '100%', maxHeight: '35vh', maxWidth: "calc(100%)"}}
          onClick={this.toggleAnimation}
        />

        <Typography variant='h2'>Eight sides</Typography>

        <Typography variant='body1'>Imagine your room contains an octagon (8-sided shape) and you are in the centre.</Typography>
        <Typography variant='body1'>For each side you will take three photos at different angles.</Typography>

        <img src={dot3Image} style={{display: 'block', margin: '1em auto'}} alt="Step three of three" />
        <div style={{display: 'flex', justifyContent: 'space-between'}}>
          <NavigationButton styleType="SEPARATED" variant='text' onClick to={Routing.path(Routing.BOARDING1)} label="Previous"/>
          <NavigationButton styleType="SEPARATED" variant='text' onClick to={Routing.path(Routing.APP_LANDING)} label="Let's start"/>
        </div>

      </>
    );
  }
}

export default OnBoarding2;
