import React from 'react';
import Typography from '@mui/material/Typography';

class PageNotFound extends React.Component {
  render() {
    return (
      <>
        <Typography variant="h2">That page doesn't exist</Typography>
        <Typography variant="body1">
          Try Re-scanning the QR Code to restart the process, if the problem still exists then contact your Supervisor.
        </Typography>
      </>
    );
  }
}

export default PageNotFound;
