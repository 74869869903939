import { default as Home } from './Home';
import { default as PageNotFound } from './PageNotFound';
import { default as Error } from './Error';
import { default as Camera } from './Camera';
import { default as OnBoarding1 } from './OnBoarding1';
import { default as OnBoarding2 } from './OnBoarding2';
import { default as AppLanding } from './AppLanding';
import { default as UploadImages } from './UploadImages';

const Pages = {
  Home,
  PageNotFound,
  Error,
  Camera,
  OnBoarding1,
  OnBoarding2,
  AppLanding,
  UploadImages
};
export default Pages;