import React from 'react';
// Import webRTC shim methods for browser support
// eslint-disable-next-line
import adapter from 'webrtc-adapter';
import { CAMERA_NO_ACCESS } from '../constants/PermissionsError';
import Routing from '../utils/Routing';

const styles = {
  videoContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: 'calc(100%)',
    height: 'calc(100%)',
    objectFit: 'cover',
    background: 'black',
    zIndex: -999,
  }
}

class CameraWebRTC extends React.Component {
  //TODO: Verify this works on other iOS devices as aspectRatio compatibility is '?' on MDN documentation
  constraints = {
    video: {
      frameRate: 30,
      facingMode: { exact: 'environment' },
      width: { min: 1024, ideal: 1920, max: 1920 },
      height: { min: 576, ideal: 1080, max: 1080 },
      aspectRatio: { exact: 16 / 9 },
    },
    audio: false
  };

  //More lenient constraints in case of failure with the more strict constraints
  alternativeConstraints = {
    video: {
      facingMode: { exact: 'environment' },
      width: { ideal: 1920, max: 1920 },
      height: { ideal: 1080, max: 1080 },
    },
    audio: false
  }

  state = {
    loading: true,
    pass: false,
    stream: undefined,
  }

  componentDidMount() {
    this.createMediaStream();
  }

  componentWillUnmount() {
    if (!this.state.stream) return;
    this.state.stream.getTracks().forEach(track => track.stop());
  }

  handleSuccess = (stream) => {
    this.setState({
      loading: false,
      pass: true,
      stream: stream,
    });
  }

  handleFailure = () => {
    this.setState({
      loading: false,
      pass: false
    });
    this.props.navigate(Routing.path(Routing.ERROR), { state: { errorType: CAMERA_NO_ACCESS, } });
  }

  openCamera = (video) => {
    if (!video || !this.state.stream) return;
    video.srcObject = this.state.stream;
    if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) video.onloadedmetadata = function (_e) {
      video.play();
    };

    this.video = video;
  }

  createMediaStream = async (retry = true) => {
    /*TODO: Fix me. Handling of OverConstrainedError; Inelegant solution as it will always try again even on desktops
     * Possible solution: Use OverconstrainedError.constraint however compatibility is uncertain on iOS devices.
     */

    /*
    Trial for better device compatibility (RVTMOB-219). Using navigator.getUserMedia. To test if functionality/camera
    will render correctly on the older devices we want: Use Chrome 52 or iOS Safari 11.
    You can download an older version of chrome from: https://www.slimjet.com/chrome/google-chrome-old-version.php
    Can try iOS/android emulators, however won't be able to verify guidance. Will need iOS + android devices with Chrome 52.
     */
    try {
      if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
        retry ? await navigator.getUserMedia(this.constraints, this.handleSuccess, this.handleFailure) :
          await navigator.getUserMedia(this.alternativeConstraints, this.handleSuccess, this.handleFailure);
      }
      else {
        const stream = retry ? await navigator.mediaDevices.getUserMedia(this.constraints) :
          await navigator.mediaDevices.getUserMedia(this.alternativeConstraints);
        this.handleSuccess(stream);
      }
    } catch (err) {
      if (retry) {
        let options;
        if (navigator.mediaDevices && navigator.mediaDevices.enumerateDevices) {
          try {
            navigator.mediaDevices.enumerateDevices()
              .then(function (devices) {
                devices.forEach(function (device) {
                  if (device.kind === 'videoinput') {
                    if (device.label.toLowerCase().search("back") > -1)
                      options = device.deviceId;
                  }
                });
              });
            if (options) {
              this.alternativeConstraints.video.deviceId = options;
              delete this.alternativeConstraints.video.facingMode;
            }
          } catch (e) {
            console.log(e);
          }
        }
        return this.createMediaStream(false);
      }
      else {
        this.handleFailure();
      }
    }
  }

  render() {
    if (this.state.loading || !this.state.pass) {
      return null;
    } else if (!this.state.loading && this.state.pass) {
      return (
        <video
          style={styles.videoContainer}
          autoPlay
          playsInline
          muted
          ref={this.openCamera}
        >Video unavailable, make sure you have granted permission.
        </video>
      );
    }
  }
}

export default CameraWebRTC;
