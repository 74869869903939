import { createTheme } from '@mui/material/styles';

export const navigationIndicator = {
  position: 'absolute',
  backgroundColor: '#0657cc',
  boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.5)',
  '&:hover, &:focus, &:disabled, &:active': {
    backgroundColor: '#0657cc',
    color: '#0657cc',
    opacity: 1,
  },
};

const theme = {
  palette: {
    primary: {
      main: '#006dae',
      light: '#549be0',
      dark: '#00437e',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#505050',
      light: '#7c7c7c',
      dark: '#282828',
      contrastText: '#ffffff',
    },
    text: {
      disabled: '#797677',
    },
    error: {
      main: '#D03232',
    },
    success: {
      main: '#41817E',
    },
    warning: {
      main: '#CE4C11',
    },
  },
  typography: {
    useNextVariants: true,
    fontSize: 14.5,
    fontFamily: 'Roboto',
    body1: {
      fontSize: '2.1vh',
      marginBottom: '0.5em',
      fontFamily: 'Roboto',
    },
    h1: {
      position: 'absolute',
      left: '-10000px',
      top: 'auto',
      width: '1px',
      height: '1px',
      overflow: 'hidden',
    },
    h2: {
      color: '#0f70d7',
      fontWeight: '500',
      fontSize: '1.1rem',
      marginTop: '1rem',
      marginBottom: '1rem',
    },
    h3: {
      fontSize: '1.4rem',
      marginBottom: '0.5em',
    },
    h4: {
      fontSize: '1.3rem',
      marginBottom: '0.5em',
    },
    h6: {
      fontWeight: 'normal',
    },
  },
  navigationIndicator: navigationIndicator,
  components: {
    MuiButton: {
      styleOverrides: {
        "root": {
          "&.Mui-disabled": {
            "color": "#797677",
          },
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          display: 'flex',
        }
      },
    },
  },
};

export default createTheme(theme);
