import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import DeviceOrientation, { Orientation } from 'react-screen-orientation';
import * as params from '../../utils/PhotoTimeoutParameters.js'

const styles ={
    circularProgressBar: {
        position: 'absolute',
        left: '50%',
        top: '50%',
        display: 'flex',
        flexDirection: 'column',
        zIndex: 1,
        transform: 'translate(-50%, -50%)',
    },
};

export default function CircularProgressBarUpdate() {
    const [progress, setProgress] = React.useState(0);

    React.useEffect(() => {
        setTimeout(() => {
            const timer = setInterval(() => {
                setProgress((prevProgress) => (prevProgress >= 100 ? 100 : prevProgress + 100 / 30));
            }, 50);

            return () => {
                clearInterval(timer);
            };
        }, params.CIRCLEFOCUS_DURATION);
    }, []);

    return (
        <DeviceOrientation lockOrientation={'landscape'}>
            <Orientation orientation='landscape' alwaysRender={false}>
                <div style={styles.circularProgressBar}>
                    <CircularProgress variant="determinate" value={progress} thickness={2} size="3.8rem"
                                      style={{transform: "rotate(-50deg)", color: "#1f6ee1"}}/>
                </div>
            </Orientation>
            <Orientation orientation='portrait' alwaysRender={false}>
                <div style={styles.circularProgressBar}>
                    <CircularProgress variant="determinate" value={progress} thickness={2} size="3.8rem"
                                      style={{transform: "rotate(50deg)", color: "#1f6ee1"}}/>
                </div>
            </Orientation>
        </DeviceOrientation>
    );
}
