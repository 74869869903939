import NetworkService from './NetworkService';
import VerifyService from './VerifyService';
import { MAX_IMAGE_SIZE } from '../constants/ImageConstraints';
import { LOST_DATA } from '../constants/ExamInformationError';
import { DEMO } from '../constants/login';
import { fakeUploadImage } from '../utils/FakeUploadImage';
import compressImage from '../utils/ImageCompressor';

export default class ImageUploadService {
    //TODO: This is fairly messy but will work for now.
    static async uploadImage(image, coordinates, examSlotId, passcode, attemptNumber=0) {
        if (VerifyService.getDemoModeToggle() === DEMO) {
            return fakeUploadImage();
        }

        let formData = new FormData();
        formData.append('file', image);
        formData.append('coordinates', coordinates);

        console.log('Upload attempt number: ', attemptNumber);
        if (attemptNumber === 2){
            return await NetworkService.post(`/mert/image/${examSlotId}?passcode=${passcode}`, formData, 'multipart/form-data');
        }

        try {
            const response = await NetworkService.post(`/mert/image/${examSlotId}?passcode=${passcode}`, formData, 'multipart/form-data');
            return response;
        } catch (err) {
            return this.uploadImage(image, coordinates, examSlotId, passcode, attemptNumber + 1);
        }
    }

    static async uploadImagesList(imageList, examSlotId, passcode) {
        if (!imageList) return;
        if (imageList.size > 24) imageList = imageList.slice(0, 24);

        if (!examSlotId || !passcode) {
            throw new Error(LOST_DATA);
        }

        for (const photoObject of imageList) {
            const imageCoordString = `${photoObject.coords.alpha} ${photoObject.coords.beta} ${photoObject.coords.gamma}`
            const compressedImage = photoObject.image.size > MAX_IMAGE_SIZE ? await compressImage(photoObject.image)
                : photoObject.image;
            await this.uploadImage(compressedImage, imageCoordString, examSlotId, passcode, 0);
        }
    }
}