import React from 'react';
import IconButton from '@mui/material/IconButton';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import DeviceOrientation, { Orientation } from 'react-screen-orientation';
import { navigationIndicator } from '../../config/theme';

const styles = {
  arrowFormat: navigationIndicator,
  rotateLeftArrowPor: {
    position: "absolute",
    left: '50%',
    top: '25%',
    display: 'flex',
    flexDirection: 'column',
    transform: 'translate(-50%, -50%)',
  },
  rotateLeftTextPor: {
    position: 'absolute',
    height: '200px',
    color: 'white',
    writingMode: 'vertical-rl',
    textOrientation: 'mixed',
    fontSize: '24px',
    left: '50%',
    top: '-85%',
    display: 'flex',
    flexDirection: 'column',
    transform: 'translate(-50%, -50%)',
  },
  rotateLeftArrowLan: {
    position: "absolute",
    left: '25%',
    top: '50%',
    display: 'flex',
    flexDirection: 'column',
    transform: 'translate(-50%, -50%)',
  },
  rotateLeftTextLan: {
    position: 'absolute',
    color: 'white',
    width: '200px',
    writingMode: 'horizontal-tb',
    textOrientation: 'mixed',
    fontSize: '24px',
    left: '-85%',
    top: '50%',
    display: 'flex',
    flexDirection: 'column',
    transform: 'translate(-50%, -50%)',
  },
};

class SpinLeftArrow extends React.Component{

  render() {
    return (
      <>
        <DeviceOrientation lockOrientation={'landscape'}>
          <Orientation orientation='landscape' alwaysRender={false}>
            <IconButton
              sx={{ ...styles.arrowFormat, ...styles.rotateLeftArrowLan }}
              disabled
              size="large">
              <ArrowLeftIcon fontSize="large" style={{fill: "white", transform: "scale(1.5)"}}/>
              <div style={styles.rotateLeftTextLan}>Pan left</div>
            </IconButton>
          </Orientation>
          <Orientation orientation='portrait' alwaysRender={false}>
            <IconButton
              sx={{ ...styles.arrowFormat, ...styles.rotateLeftArrowPor }}
              disabled
              size="large">
              <ArrowDropUpIcon fontSize="large" style={{fill: "white", transform: "scale(1.5)"}}/>
              <div style={styles.rotateLeftTextPor}>Pan left</div>
            </IconButton>
          </Orientation>
        </DeviceOrientation>
      </>
    );
  }
}

export default SpinLeftArrow;
