import React from "react";
const RotatePhoneSvg = (props) => (
<svg viewBox="0 0 138 138" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" {...props}>
    <title>Rotate Phone Left</title>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Desktop-HD" transform="translate(-1037.000000, -119.000000)">
            <g id="Rotate-Phone-6" transform="translate(1037.000000, 119.000000)">
                <circle id="Oval" stroke="#0657CC" strokeWidth="5" fill="#0657CC" cx="69" cy="69" r="66.5"></circle>
                <g id="Phone" transform="translate(68.679455, 67.000000) rotate(-45.000000) translate(-68.679455, -67.000000) translate(43.179455, 18.000000)">
                    <rect id="Phone-Outer" stroke="#FFFFFF" strokeWidth="2" fill="#000000" x="-1" y="-1" width="53" height="100" rx="4"></rect>
                    <rect id="Speaker" fill="#FFFFFF" x="18" y="3" width="14" height="2" rx="1"></rect>
                    <rect id="Screen" fill="#FFFFFF" x="2" y="8" width="47" height="79"></rect>
                    <circle id="Phone-Button" fill="#FFFFFF" cx="25.5" cy="92.5" r="3.5"></circle>
                    <rect id="Phone-Outer" fill="#FFFFFF" x="0" y="0" width="51" height="98" rx="4"></rect>
                    <rect id="Speaker" fill="#0657CC" x="18" y="3" width="14" height="2" rx="1"></rect>
                    <rect id="Screen" fill="#0657CC" x="2" y="8" width="47" height="79"></rect>
                    <circle id="Phone-Button" fill="#0657CC" cx="25.5" cy="92.5" r="3.5"></circle>
                </g>
                <g id="Arrow-R" transform="translate(74.179455, 14.000000)">
                    <path d="M10,9 C19.2202865,11.5536199 26.8869532,15.8869532 33,22 C39.1130468,28.1130468 42.7797135,37.1130468 44,49" id="Path-2" stroke="#FFFFFF" strokeWidth="5"></path>
                    <polygon id="Triangle" fill="#FFFFFF" transform="translate(7.000000, 8.000000) rotate(-75.000000) translate(-7.000000, -8.000000) " points="7 3 13 13 1 13"></polygon>
                </g>
                <g id="Arrow-L" transform="translate(44.179455, 97.500000) scale(-1, -1) translate(-44.179455, -97.500000) translate(22.179455, 73.000000)">
                    <path d="M10,9 C19.2202865,11.5536199 26.8869532,15.8869532 33,22 C39.1130468,28.1130468 42.7797135,37.1130468 44,49" id="Path-2" stroke="#FFFFFF" strokeWidth="5"></path>
                    <polygon id="Triangle" fill="#FFFFFF" transform="translate(7.000000, 8.000000) rotate(-75.000000) translate(-7.000000, -8.000000) " points="7 3 13 13 1 13"></polygon>
                </g>
            </g>
        </g>
    </g>
</svg>)

export default RotatePhoneSvg