export const TARGET_ROTATION = "TROT";
export const TARGET_INCLINATION = "TINC";
export const TARGET_SPIN = "TSPN";
export const TARGET_NONE = "TNON";
export const DIRECTION_NONE = "NONE";
export const ROTATE_LEFT = "ROTATE_LEFT";
export const ROTATE_RIGHT = "ROTATE_RIGHT";
export const TILT_UP = "TILT_UP";
export const TILT_DOWN = "TILT_DOWN";
export const SPIN_LEFT = "SPIN_LEFT";
export const SPIN_RIGHT = "SPIN_RIGHT";
export const ON_TARGET = {
    value: 0,
    direction: DIRECTION_NONE
};
export const ROTATION_VECTOR_UPDATE = "RotationVectorUpdate";
export const INCLINATION_VECTOR_UPDATE = "InclinationVectorUpdate";
export const SPIN_VECTOR_UPDATE = "SpinVectorUpdate";
export const TARGET_VECTOR_UPDATE = "TargetVectorUpdate";
export const TARGET_INDEX_UPDATE = "TargetIndexUpdate";
