import React from 'react';
import Button from '@mui/material/Button';
import AutoPhoto from './NavigationCluster/AutoPhoto';
import CenteredBox from './CenteredBox';
import Inclination from './NavigationCluster/Inclination';
import Rotation from './NavigationCluster/Rotation';
import Spin from './NavigationCluster/Spin';
import * as vcConsts from '../phone-orientation/VectorCalculatorConstants';
import VectorCalculator from '../phone-orientation/VectorCalculator';
import Routing from '../utils/Routing';

class NavigationCluster extends React.Component {
    constructor(props) {
        super(props);
        this.vectorCalculator = new VectorCalculator();

        this.state = {
            rotationDirection: vcConsts.DIRECTION_NONE,
            inclinationDirection: vcConsts.DIRECTION_NONE,
            spinDirection: vcConsts.DIRECTION_NONE,
            readyForPhoto: false,
            photoCount: 0,
            totalPhotos: 0
        }
        this.internalState = {
            currentTargetIndex: -1,
            totalTargets: 0,
            targetVector: vcConsts.TARGET_ROTATION,
            rotationVector: vcConsts.ON_TARGET,
            inclinationVector: vcConsts.ON_TARGET,
            spinVector: vcConsts.ON_TARGET,
            photos: [],
        };
    }

    componentDidMount() {
        window.addEventListener(vcConsts.ROTATION_VECTOR_UPDATE, this.updateRotationVector.bind(this));
        window.addEventListener(vcConsts.INCLINATION_VECTOR_UPDATE, this.updateInclinationVector.bind(this));
        window.addEventListener(vcConsts.SPIN_VECTOR_UPDATE, this.updateSpinVector.bind(this));
        window.addEventListener(vcConsts.TARGET_VECTOR_UPDATE, this.updateTargetVector.bind(this));
        window.addEventListener(vcConsts.TARGET_INDEX_UPDATE, this.updateTargetIndex.bind(this))

        this.vectorCalculator.startDataFeed();
    }

    componentWillUnmount() {
        this.vectorCalculator.stopDataFeed();
    }

    pushToUpload = () => {
        if (!(this.internalState.currentTargetIndex < this.internalState.totalTargets) &&
            this.internalState.currentTargetIndex > -1) {
            return this.props.navigate(Routing.UPLOAD_IMAGE, { state: { photos: this.internalState.photos } });
        }
    }

    updateRotationVector(e) {
        if (this.internalState.rotationVector.direction !== e.detail.direction &&
            this.internalState.targetVector === vcConsts.TARGET_ROTATION) {
            this.setState({
                rotationDirection: e.detail.direction
            });
        }
        this.internalState.rotationVector = e.detail;
    }

    updateInclinationVector(e) {
        if (this.internalState.inclinationVector.direction !== e.detail.direction &&
            this.internalState.targetVector === vcConsts.TARGET_INCLINATION) {
            this.setState({
                inclinationDirection: e.detail.direction
            });
        }
        this.internalState.inclinationVector = e.detail;
    }

    updateSpinVector(e) {
        if (this.internalState.spinVector.direction !== e.detail.direction &&
            this.internalState.targetVector === vcConsts.TARGET_SPIN) {
            this.setState({
                spinDirection: e.detail.direction
            });
        }
        this.internalState.spinVector = e.detail;
    }

    updateTargetVector(e) {
        this.internalState.targetVector = e.detail;
        switch (e.detail) {
            case vcConsts.TARGET_ROTATION:
                this.setState({
                    rotationDirection: this.internalState.rotationVector.direction,
                    inclinationDirection: vcConsts.DIRECTION_NONE,
                    spinDirection: vcConsts.DIRECTION_NONE,
                    readyForPhoto: false
                });
                break;
            case vcConsts.TARGET_INCLINATION:
                this.setState({
                    rotationDirection: vcConsts.DIRECTION_NONE,
                    inclinationDirection: this.internalState.inclinationVector.direction,
                    spinDirection: vcConsts.DIRECTION_NONE,
                    readyForPhoto: false
                });
                break;
            case vcConsts.TARGET_SPIN:
                this.setState({
                    rotationDirection: vcConsts.DIRECTION_NONE,
                    inclinationDirection: vcConsts.DIRECTION_NONE,
                    spinDirection: this.internalState.spinVector.direction,
                    readyForPhoto: false
                });
                break;
            case vcConsts.TARGET_NONE:
                this.setState({
                    rotationDirection: vcConsts.DIRECTION_NONE,
                    inclinationDirection: vcConsts.DIRECTION_NONE,
                    spinDirection: vcConsts.DIRECTION_NONE,
                    readyForPhoto: Boolean(this.internalState.currentTargetIndex < this.internalState.totalTargets)
                });
                break;
            default:
                alert("Something went wonky.");
        }
    }

    updateTargetIndex(e) {
        this.internalState.currentTargetIndex = e.detail.index;
        this.internalState.totalTargets = e.detail.length;
        this.setState({
            photoCount: this.internalState.currentTargetIndex,
            totalPhotos: this.internalState.totalTargets
        });
    }

    photoConsumerCallback = (image, alpha, beta, gamma) => {
        this.internalState.photos.push({
            image: image,
            coords: {
                alpha: alpha,
                beta: beta,
                gamma: gamma
            }
        });
    }

    render() {
        return (
            <>
                {this.internalState.currentTargetIndex <= this.internalState.totalTargets &&
                <>
                    <Rotation nextDirection={this.state.rotationDirection}/>
                    <Inclination nextDirection={this.state.inclinationDirection}/>
                    <Spin nextDirection={this.state.spinDirection}/>
                    <AutoPhoto videoContainer={this.props.videoContainer}
                               readyForPhoto={this.state.readyForPhoto}
                               photoCount={this.state.photoCount}
                               totalPhotos={this.state.totalPhotos}
                               captureCallback={this.vectorCalculator.photoTaken}
                               photoConsumerCallback={this.photoConsumerCallback}/>
                </>
                }
                {this.internalState.currentTargetIndex >= this.internalState.totalTargets &&
                <CenteredBox handleClick={this.pushToUpload}>
                    <p style={{textAlign: "center"}}>You have taken all requisite photos.</p>
                    <div style={{justifyContent: "right", textAlign: "right", marginTop: 'auto'}}>
                        <Button color="primary"
                                style={{textTransform: "capitalize", width: "calc(100%)", textAlign: "right",}}
                                onClick={this.pushToUpload}
                        >Start uploading
                        </Button>
                    </div>
                </CenteredBox>
                }

            </>
        );
    }
}

export default NavigationCluster;
