import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { CAMERA_NO_ACCESS, COOKIES_DISABLED, ORIENTATION_NO_ACCESS } from '../constants/PermissionsError'
import { NO_ACCESS } from '../constants/login';
import Routing from '../utils/Routing';
import { AuthConsumer, authContext } from '../authContext';

const styles = {
  clipButton: {
    width: '128px',
    height: '41px',
    textAlign: 'center',
    textTransform: 'capitalize',
  }
};

class Error extends React.Component {
  state = {
    copied: false,
  };

  renderCookiesError = () => {
    return (
      <>
        <Typography variant="h2">Cookies required</Typography>
        <Typography variant="body1">Cookies are disabled in your browser. Please enable cookies in your browser preferences to continue.
        </Typography>
        <Typography variant="body1">If the problem persists, please inform your supervisor.</Typography>
      </>
    );
  }

  renderPermissionError = (type) => {
    const url = window.location.href.split("/").slice(0,3).join("/");
    const { examSlotId: examSessionId, passcode } = this.context;
    const sessionURL = `${url}/${examSessionId}?passcode=${passcode}`;

    if (type === CAMERA_NO_ACCESS) {
      return (
        <>
          <Typography variant="h2">Camera permission required</Typography>
          <Typography variant="body1">We are unable to access your camera.</Typography>
          <Typography variant="body1">If you are using an <strong>iPhone</strong></Typography>
          <Typography variant="body1" component="ol">
            <li>Refresh the Page.</li>
            <li>Tap <strong>Allow</strong> when you see the pop-up.</li>
          </Typography>
          <Typography variant="body1">If you are using an <strong>Android phone</strong></Typography>
          <Typography variant="body1" component="ol">
            <li>Reset the camera by navigating to browser settings>site settings>camera.</li>
            <li>Refresh your page.</li>
            <li>Tap <strong>Allow</strong> when you see the pop-up.</li>
          </Typography>
          <Button color="primary" onClick={() => {window.location.href=Routing.path(Routing.APP_LANDING)}}>
            Refresh page
          </Button>
        </>
      )
    } else if (type === ORIENTATION_NO_ACCESS){

      return (
        <>
          <Typography variant="h2">Device orientation permission required</Typography>
          <Typography variant="body1">We are unable to access your device's orientation.</Typography>
          <Typography variant="body1">Follow the steps below:</Typography>
          <Typography variant="body1" component="ol">
            <li>Swipe up from the bottom of the screen.</li>
            <li>Swipe up on the app's preview to close the app.</li>
            <li>Re-scan the QR code.</li>
            <li>Tap <strong>Allow</strong> when you see the pop-up.</li>
          </Typography>
          <Typography variant="body1">If that doesn't solve the issue, please try these steps below:</Typography>
          <Typography variant="body1" component="ol">
            <li>Tap <strong>"Copy QR Link"</strong> below.</li>
            <li>Open Safari in private mode .</li>
            <li>Tap the URL address bar and choose <strong>"Paste and Go"</strong></li>
            <li>Tap <strong>Allow</strong> when you see the pop-up.</li>
          </Typography>
          <div>
            <CopyToClipboard
              text={sessionURL}
              onCopy={() => this.setState({copied: true})}
            >
              <Button sx={styles.clipButton} color={"primary"} variant={"contained"} >Copy QR link</Button>
            </CopyToClipboard>
            {this.state.copied && <Typography variant="body1" color="error">Copied.</Typography>}
          </div>
        </>
      )

    }
  }

  render() {
    const permissionErrorType = this.props.location?.state?.errorType;

    return (
      <AuthConsumer>
        {( {isErrored, errorType }) => (
          <>
            {errorType === NO_ACCESS &&
              <>
                <Typography variant="h2">Permissions issue</Typography>
                <Typography variant="body1">Cannot access the application.<br/>
                  Kindly contact your supervisor for assistance.
                </Typography>
              </>
            }
            {errorType === COOKIES_DISABLED &&
              <>
                {this.renderCookiesError()}
              </>
            }
            {!permissionErrorType && (errorType !== NO_ACCESS && errorType !== COOKIES_DISABLED) && isErrored &&
              <>
                <Typography variant="h2">Error</Typography>
                <Typography variant="body1">
                    This system is currently unavailable. Please contact your supervisor.
                </Typography>
              </>
            }
            {(permissionErrorType === CAMERA_NO_ACCESS || permissionErrorType === ORIENTATION_NO_ACCESS) &&
              <>
                {this.renderPermissionError(permissionErrorType)}
              </>
            }
        </>
        )}
      </AuthConsumer>
    );
  }
}
Error.propTypes = {
  location: PropTypes.object,
};

export default Error;
Error.contextType = authContext;
