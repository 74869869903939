import React from 'react';
import DeviceOrientation, { Orientation } from 'react-screen-orientation';
import RotatePhoneSvg from '../../assets/RotatePhoneLeft';

const styles = {
    rotateFormat: {
        width: '60px',
        height: '60px',
        transform: 'rotate(90deg)',
    },
    rotateLeftPor: {
        position: 'absolute',
        left: '75%',
        top: '50%',
        display: 'flex',
        flexDirection: 'column',
        transform: 'translate(-50%, -50%)',
    },
    rotateLeftTextPor: {
        position: 'absolute',
        height: '200px',
        color: 'white',
        writingMode: 'vertical-rl',
        textOrientation: 'mixed',
        fontSize: '24px',
        left: '135%',
        top: '120%',
        display: 'flex',
        flexDirection: 'column',
        transform: 'translate(-50%, -50%)',
    },
    rotateLeftLan: {
        position: 'absolute',
        left: '50%',
        top: '25%',
        display: 'flex',
        flexDirection: 'column',
        transform: 'translate(-50%, -50%)',
    },
    rotateLeftTextLan: {
        position: 'absolute',
        color: 'white',
        width: '200px',
        writingMode: 'horizontal-tb',
        textOrientation: 'mixed',
        fontSize: '24px',
        left: '120%',
        top: '-30%',
        display: 'flex',
        flexDirection: 'column',
        transform: 'translate(-50%, -50%)',
    },
}

class RotateLeftArrow extends React.Component{

    render() {
        return (
            <>
                <DeviceOrientation lockOrientation={'landscape'}>
                    <Orientation orientation='landscape' alwaysRender={false}>
                        <div style={styles.rotateLeftLan}>
                            <div style={styles.rotateFormat}>
                                <RotatePhoneSvg/>
                            </div>
                            <div style={styles.rotateLeftTextLan}>Rotate Left</div>
                        </div>
                    </Orientation>
                    <Orientation orientation='portrait' alwaysRender={false}>
                        <div style={styles.rotateLeftPor}>
                            <div style={styles.rotateFormat}>
                                <RotatePhoneSvg/>
                            </div>
                            <div style={styles.rotateLeftTextPor}>Rotate Left</div>
                        </div>

                    </Orientation>
                </DeviceOrientation>
            </>

        )
    }
}

export default RotateLeftArrow;
