import React from "react";
import TiltUpArrow from "./InclinationUpArrow";
import TiltDownArrow from "./InclinationDownArrow";
import {TILT_UP, TILT_DOWN} from "../../phone-orientation/VectorCalculatorConstants";

class Inclination extends React.Component{

    render() {
        let tiltUp = false;
        let tiltDown = false;
        if (this.props.nextDirection === TILT_UP) {
            tiltUp = true;
        } else if (this.props.nextDirection === TILT_DOWN) {
            tiltDown = true;
        }
        return (
            <div>
                {tiltUp && <TiltUpArrow />}
                {tiltDown && <TiltDownArrow />}
            </div>
        );
    }
}

export default Inclination