import React from 'react';
// Import webRTC shim methods for browser support
// eslint-disable-next-line
import adapter from 'webrtc-adapter';
import Typography from '@mui/material/Typography';
import NavigationButton from '../components/NavigationButton';
import Routing from '../utils/Routing';
import androidImage from '../assets/android.svg';
import iOSImage from '../assets/ios.svg';
import weChatImage from '../assets/wechat.svg';
import dot1Image from '../assets/dot1st.svg';
import MERTonboard1Image from '../assets/MERTonboard1.svg';

class Home extends React.Component {
  renderError() {
    return (
      <>
        <h3>Use a different browser</h3>
        <p>Your browser is incompatible with this application!</p>
        <p>
          Please access this page URL with https and use the following compatible browser:
        </p>
        <ul>
          <li>
            <img src={androidImage} alt="" /> Android <br />
            Google Chrome (Version 52+)
          </li>
        </ul>
        <ul>
          <li>
            <img src={iOSImage} alt="" /> iOS <br />
            Safari (Version 11+)
          </li>
        </ul>
        <hr style={{ border: '0', borderTop: '1px solid #7c7c7c' }}></hr>
        <p>
          <em>(If you are using WeChat <img src={weChatImage} alt="" /> please try using the <strong>in-built-camera</strong> app on your phone to scan the QR code.)</em>
        </p>
      </>
    );
  }

  renderPageContent() {
    return (
      <>
        <img src={MERTonboard1Image}
          style={{ display: 'block', margin: '0 auto', width: 'auto', height: '100%', maxHeight: '25vh', maxWidth: 'calc(100%)' }}
          alt="A mobile phone with the screen showing a camera pointing directly at a desk with laptop on it"
        />

        <Typography variant="h2">Photographing your exam room</Typography>

        <Typography variant="body1">
          Use this app to photograph your exam room safely and securely to ensure correct setup.
        </Typography>
        <Typography variant="body1">
          Your supervisor won't be able to see the images you are taking. Only authorised Monash Staff will access this if required.
        </Typography>
        <img src={dot1Image} style={{ display: 'block', margin: '1em auto' }} alt="Step one of three" />
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <NavigationButton variant='text' styleType="SEPARATED" onClick to={Routing.APP_LANDING} label="Skip onboarding" />
          <NavigationButton variant='text' styleType="SEPARATED" onClick to={Routing.BOARDING1} label="Next" />
        </div>
      </>
    );
  }

  renderFrontPage() {
    try {
      //Checking browser compatibility
      if ((navigator.getUserMedia || (navigator.mediaDevices && navigator.mediaDevices.getUserMedia))
        && window.DeviceMotionEvent && !(/micromessenger/i.test(navigator.userAgent))) {
        return this.renderPageContent();
      } else {
        return this.renderError();
      }
    } catch (err) {
      return this.renderError();
    }
  }


  render() {
    return this.renderFrontPage();
  }
}

export default Home;
