import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';

import theme from './config/theme';
import App from './App';
import AuthWrapper from './components/wrapper/AuthWrapper';
import './index.css';

const root = createRoot(document.getElementById('root'));
root.render(
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <AuthWrapper>
          {<App />}
        </AuthWrapper>
      </BrowserRouter>
    </ThemeProvider>
  </StyledEngineProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
