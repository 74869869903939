import React from 'react';
import { ROTATE_LEFT, ROTATE_RIGHT } from '../../phone-orientation/VectorCalculatorConstants';
import RotateLeftArrow from './RotateLeftArrow';
import RotateRightArrow from './RotateRightArrow';

class Rotation extends React.Component {
  render() {
    let rotateLeft = this.props.nextDirection === ROTATE_LEFT;
    let rotateRight = this.props.nextDirection === ROTATE_RIGHT;
    return (
      <>
        {rotateLeft && <RotateLeftArrow/>}
        {rotateRight && <RotateRightArrow/>}
      </>
    );
  }
}

export default Rotation;