import React from 'react';
import { useNavigate } from 'react-router-dom';
import Auth from '../Auth';

function AuthWrapper(props) {
  const navigate = useNavigate();
  return (
    <Auth navigate={navigate}>{props.children}</Auth>
  );
}

export default AuthWrapper;