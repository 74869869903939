import React from 'react';
import { Box, CircularProgress } from '@mui/material';
import { authContext } from '../authContext';
import StartButton from '../components/StartButton';
import { default as UploadService } from '../services/ImageUploadService';
import greenTickImage from '../assets/greenTick.svg';
import { LOST_DATA } from '../constants/ExamInformationError';

class UploadImages extends React.Component {
    state = {
        isErrored: false,
        requestPending: false,
        uploadComplete: window.history.state?.uploadComplete || false,
        errorMessage: '',
    }

    initialiseUpload = async (imageList) => {
        if (!imageList || imageList.length === 0) {
            console.error('Error: images to upload are missing');
            const message = 'Sorry, there was an error with the image capture. Please try rescanning the QR Code.'
            return this.setState({
                isErrored: true,
                requestPending: false,
                uploadComplete: false,
                errorMessage: message
            });
        }

        this.setState({isErrored: false, requestPending: true, uploadComplete: false});

        try {
            await UploadService.uploadImagesList(imageList, this.context.examSlotId, this.context.passcode);
            sessionStorage.clear();

            this.setState({uploadComplete: true, requestPending: false, isErrored: false, imageUploadCount: this.state.imageUploadCount + imageList.length})
            window.history.replaceState({uploadComplete: true},null, window.location.pathname); //A bit inelegant, but it will show success page upon pressing the back button.
            window.history.pushState({uploadComplete: true},null, window.location.pathname);
        } catch (err) {
            this.setState({
                isErrored: true,
                requestPending: false,
                errorMessage: err.message === LOST_DATA ? LOST_DATA : "We weren't able to upload your photos due to a network issue."
            });
        }
    }

    componentDidMount() {
        const state = this.props.location ? this.props.location.state : undefined;
        const imageList = state ? state.photos : undefined;

        //Prevent user from pressing back-button to go to camera page again
        window.history.pushState(this.state,null, window.location.pathname);

        if (!this.state.uploadComplete) {
            this.initialiseUpload(imageList);
        }
    }


    render() {
        const { requestPending, isErrored, uploadComplete, errorMessage } = this.state;
        // the height of the screen - (the (tallest) height of the header + page wrapper padding)
        const fullScreenContainerHeight = 'calc(100vh - 112px)';
        // the width of the screen - page wrapper padding
        const fullScreenContainerWidth = 'calc(100vw - 48px)';
        return (
            <Box alignItems="stretch" display="flex" minHeight={fullScreenContainerHeight} width={fullScreenContainerWidth}>
                <Box
                  display="flex"
                  flex="1"
                  flexDirection="column"
                  maxWidth="100%"
                  textAlign="center"
                  sx={{
                      gap: { md: 4 },
                      justifyContent: { md: 'center'},
                      '@media (orientation: portrait)': { justifyContent: 'center', gap: 4 },
                  }}
                >

                    {requestPending && !isErrored &&
                    <>
                        <p>All photos are now uploading...</p>
                        <div style={{textAlign: "center", justifyContent: "center"}}>
                            <CircularProgress disableShrink color="inherit"/>
                        </div>
                    </>
                    }
                    {!isErrored && !requestPending && uploadComplete &&
                    <>
                        <div>
                            <img src={greenTickImage} alt="Green tick" style={{
                                display: 'block',
                                margin: '0 auto',
                                maxHeight: '30vh',
                                maxWidth: '50%',
                            }}/>
                            <p>Well done! You are all set.</p>
                        </div>
                        <p><strong>
                            Put your phone on silent and place it face down and out of reach during your assessment.
                        </strong></p>
                        <p>You can now continue with your check in.</p>
                    </>
                    }
                    {isErrored && !requestPending &&
                    <div>
                        <p>{errorMessage}</p>
                        <p>If the problem persists, talk to your online supervisor.</p>
                        {errorMessage !== LOST_DATA &&
                        <StartButton handleClick={() => this.initialiseUpload(this.props.location?.state?.photos)}
                                     label="Re-upload photos"/>
                        }
                    </div>
                    }
                </Box>
            </Box>
        )
    }
}

export default UploadImages;
UploadImages.contextType = authContext;
