import React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { AppBar, Box, Toolbar, Typography } from '@mui/material';

import MonashLogo from './MonashLogo';
import Routing from '../utils/Routing';

const styles = {
  headerContainer: {
    zIndex: 9999,
  },
  header: {
    backgroundColor: 'secondary.contrastText',
    justifyContent: 'center',
  },
  main: {
    display: 'flex',
    backgroundColor: 'secondary.contrastText',
  },
  pageContent: {
    flex: 1,
    padding: 3,
    boxSizing: 'border-box',
  },
};

/**
 * This takes the view of the current page and wraps it with various semantic
 * components (a header and footer, a menu for general navigation)
 */
function PageWrapper(props) {
  const location = useLocation();
  const { children } = props;

  const renderHeaderWrapper = () => {
    return (
        <>
          <AppBar elevation={1} position="sticky" component="header" sx={styles.headerContainer}>
            <Typography variant="h1">Exam room scan (eMERS)</Typography>
            <Toolbar sx={styles.header}>
              <MonashLogo />
            </Toolbar>
          </AppBar>
          <main style={styles.main}>
            <Box sx={styles.pageContent}>{children}</Box>
          </main>
        </>
    );
  };

  const renderNoHeader = () => {
    return (
          <main style={styles.main}>
            <div style={{width: `calc(100%)`, flex: "1"}}>{children}</div>
          </main>
    );
  };

  const renderWrapper = () => {
    const path = location?.pathname;

    if (path && path.startsWith(Routing.path(Routing.CAMERA))) {
      return renderNoHeader();
    }
    return renderHeaderWrapper();
  };


  return renderWrapper();
}

PageWrapper.propTypes = {
  children: PropTypes.node,
};

export default PageWrapper;