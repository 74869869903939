import "./lib/fulltilt"; // REQUIRED as dependency for GyroNorm (manual fix because of NPM shenanigans)
import GyroNorm from 'gyronorm';
import * as consts from "./VectorCalculatorConstants";
import * as params from "./VectorCalculatorParameters";

class VectorCalculator {

    constructor() {
        let args = {
            frequency: params.CALCULATION_PULSE_RATE, // ( How often the object sends the values - milliseconds )
            gravityNormalized: true,           // ( If the gravity related values to be normalized )
            orientationBase: GyroNorm.WORLD,   // ( Can be GyroNorm.GAME or GyroNorm.WORLD. gn.GAME returns orientation values with respect to the head direction of the device. gn.WORLD returns the orientation values with respect to the actual north direction of the world. )
            decimalCount: 3,                   // ( How many digits after the decimal point will there be in the return values )
        };
        this.gn = new GyroNorm();
        this.gnPromise = this.gn.init(args).catch(error => console.error('Error in GyroNorm library', error));

        this.destinations = this.calculateDestinations();
        this.storedOffset = -1;
        this.lastData = null;

        this.internalState = {
            currentDestinationIndex: -1,
            totalDestinations: this.destinations.length,
            targetVector: consts.TARGET_ROTATION,
            rotationVector: consts.ON_TARGET,
            inclinationVector: consts.ON_TARGET,
            spinVector: consts.ON_TARGET
        }
    }

    calculateDestinations() {
        var destinations = [];
        let inclinations = [-5, 30, -40];
        for (let spn = 360; spn > 0; spn-=45) {
            inclinations.forEach(function(inc) {
                destinations.push({
                    spin: spn,
                    inclination: inc
                });
            });
        }
        return destinations;
    }

    startDataFeed() {
        let self = this;
        this.gnPromise.then(function() {
            self.gn.start(function(data) {
                self.lastData = data;
                self.calculate(data);
            })
        });
    }

    stopDataFeed() {
        this.gn.end();
    }

    calculate(data) {
        if (this.internalState.currentDestinationIndex === -1) {
            this.nextDestination();
        }

        let rotationVector = this.calculateNextRotationVector(data);
        if (!(this.internalState.rotationVector.direction === consts.DIRECTION_NONE &&
            rotationVector.direction === consts.DIRECTION_NONE)) {
            this.updateRotationVector(rotationVector);
        }

        let inclinationVector = this.calculateNextInclinationVector(data);
        if (this.internalState.inclinationVector.direction !== consts.DIRECTION_NONE ||
            inclinationVector.direction !== consts.DIRECTION_NONE) {
            this.updateInclinationVector(inclinationVector);
        }

        let spinVector = this.calculateNextSpinVector(data);
        if (!(this.internalState.spinVector.direction === consts.DIRECTION_NONE &&
            spinVector.direction === consts.DIRECTION_NONE)) {
            this.updateSpinVector(spinVector)
        }

        if (this.targetVectorUpdateRequired()) {
            this.updateTargetVector(this.nextTargetVector());
        }
    }

    updateRotationVector(newRotationVector) {
        this.internalState.rotationVector = newRotationVector;
        let newEvent = new CustomEvent(consts.ROTATION_VECTOR_UPDATE, {detail: newRotationVector});
        window.dispatchEvent(newEvent);
    }

    calculateNextRotationVector(data) {
        let dest = this.getCurrentDestination();
        if (dest === null) {
            return consts.ON_TARGET;
        }
        let nominalReturn = this.calculateRotationVector(data);
        if (this.withinDeadZone(nominalReturn, params.ROTATE_DEAD_ZONE) ||
            this.withinDriftZone(this.internalState.rotationVector, nominalReturn, params.ROTATE_DRIFT)) {
            let data = Object.assign({}, consts.ON_TARGET);
            data.targetValue = 0;
            return data;
        } else {
            return nominalReturn;
        }
    }

    calculateRotationVector(data) {
        let dest = this.getCurrentDestination();
        if (dest === null) {
            return consts.ON_TARGET;
        }
        // Gamma positive, target = 180, gamma negative target = 0
        let betaTarget = data.do.gamma < 0 ? 0 : 180;
        let betaDirection = data.do.beta < 0 ? consts.ROTATE_RIGHT : consts.ROTATE_LEFT;
        let betaValue = Math.abs(betaTarget - Math.abs(data.do.beta)); // FIXME: This looks janky
        return {
            value: betaValue,
            targetValue: betaTarget,
            direction: betaDirection
        }
    }

    updateInclinationVector(newInclinationVector) {
        this.internalState.inclinationVector = newInclinationVector;
        let newEvent = new CustomEvent(consts.INCLINATION_VECTOR_UPDATE, {detail: newInclinationVector});
        window.dispatchEvent(newEvent);
    }

    calculateNextInclinationVector(data) {
        let dest = this.getCurrentDestination();
        if (dest === null) {
            return consts.ON_TARGET;
        }
        let nominalReturn = this.calculateInclinationVector(data);
        if (this.withinDeadZone(nominalReturn, params.TILT_DEAD_ZONE) ||
            this.withinDriftZone(this.internalState.inclinationVector, nominalReturn, params.TILT_DRIFT)) {
            let data = Object.assign({}, consts.ON_TARGET);
            data.targetValue = dest.inclination;
            return data;
        } else {
            return nominalReturn;
        }
    }

    calculateInclinationVector(data) {
        let dest = this.getCurrentDestination();
        if (dest === null) {
            return consts.ON_TARGET;
        }
        let orthogonalGamma = data.do.gamma > 0 ? 90 - data.do.gamma : -90 - data.do.gamma;
        let value = Math.abs(orthogonalGamma - dest.inclination);
        let direction = orthogonalGamma > dest.inclination ? consts.TILT_DOWN : consts.TILT_UP;
        return {
            value: value,
            targetValue: dest.inclination,
            direction: direction
        }
    }

    updateSpinVector(newSpinVector) {
        this.internalState.spinVector = newSpinVector
        let newEvent = new CustomEvent(consts.SPIN_VECTOR_UPDATE, {detail: newSpinVector});
        window.dispatchEvent(newEvent);
    }

    calculateNextSpinVector(data) {
        let dest = this.getCurrentDestination();
        if (this.storedOffset < 0 || dest === null) {
            return consts.ON_TARGET; // Haven't taken first photo, we don't care about spin.
        }
        let nominalReturn = this.calculateSpinVector(data);
        if (this.withinDeadZone(nominalReturn, params.SPIN_DEAD_ZONE) ||
            this.withinDriftZone(this.internalState.spinVector, nominalReturn, params.SPIN_DRIFT)) {
            let data = Object.assign({}, consts.ON_TARGET);
            data.targetValue = dest.spin;
            return data;
        } else {
            return nominalReturn;
        }
    }

    calculateSpinVector(data) {
        let dest = this.getCurrentDestination();
        if (dest === null) {
            return consts.ON_TARGET;
        }
        let currentPosition = this.getAdjustedMagneticNorth(data);
        let target = dest.spin;
        // Left = increase, right = decrease
        // When turning left if the current position is greater than the target position we will pass over the zero point
        // When turning right if the current position is less than the target position we will pass over the zero point
        let spinLeftDistance = 0
        let spinRightDistance = 0
        if (currentPosition > target) {
            spinLeftDistance = 360 - currentPosition + target;
            spinRightDistance = currentPosition - target;
        } else {
            spinLeftDistance = target - currentPosition;
            spinRightDistance = 360 - target + currentPosition;
        }

        if (spinRightDistance < spinLeftDistance) {
            return {
                value: spinRightDistance,
                targetValue: target,
                direction: consts.SPIN_RIGHT
            };
        } else {
            return {
                value: spinLeftDistance,
                targetValue: target,
                direction: consts.SPIN_LEFT
            };
        }
    }

    updateTargetVector(newTargetVector) {
        this.internalState.targetVector = newTargetVector;
        let newEvent = new CustomEvent(consts.TARGET_VECTOR_UPDATE, {detail: this.internalState.targetVector});
        window.dispatchEvent(newEvent);
    }

    nextTargetVector() {
        if (this.internalState.rotationVector.direction !== consts.DIRECTION_NONE) {
            return consts.TARGET_ROTATION;
        } else if (this.internalState.inclinationVector.direction !== consts.DIRECTION_NONE) {
            return consts.TARGET_INCLINATION;
        } else if (this.internalState.spinVector.direction !== consts.DIRECTION_NONE) {
            return consts.TARGET_SPIN
        } else {
            return consts.TARGET_NONE
        }
    }

    updateCurrentTargetIndex() {
        let newEvent = new CustomEvent(consts.TARGET_INDEX_UPDATE, {
            detail: {
                index: this.internalState.currentDestinationIndex,
                length: this.internalState.totalDestinations
            }
        });
        window.dispatchEvent(newEvent);
    }

    getAdjustedMagneticNorth(data) {
        let gammaCompensatedNorth = this.getGammaCompensatedMagneticNorth(data);
        let offsetNorth = gammaCompensatedNorth - this.storedOffset;
        return this.bringNorthBackIntoRange(offsetNorth);
    }

    getGammaCompensatedMagneticNorth(data) {
        let gammaOffset = data.do.gamma > 0 ? 180 : 0;
        let gammaCompensatedNorth = data.do.alpha + gammaOffset;
        return this.bringNorthBackIntoRange(gammaCompensatedNorth);
    }

    bringNorthBackIntoRange(northHeading) {
        if (northHeading > 360) {
            return this.bringNorthBackIntoRange(northHeading - 360);
        } else if (northHeading < 0) {
            return this.bringNorthBackIntoRange(northHeading + 360);
        } else {
            return northHeading;
        }
    }

    withinDriftZone(lastKnownState, currentState, allowedDrift) {
        if (lastKnownState.direction === consts.DIRECTION_NONE && currentState.value < allowedDrift) {
            return true;
        } else {
            return false;
        }
    }

    withinDeadZone(currentState, deadZone) {
        if (currentState.value < deadZone) {
            return true
        } else {
            return false;
        }
    }

    targetVectorUpdateRequired() {
        switch(this.internalState.targetVector) {
            case consts.TARGET_ROTATION:
                return this.internalState.rotationVector.direction === consts.DIRECTION_NONE;
            case consts.TARGET_INCLINATION:
                return this.internalState.inclinationVector.direction === consts.DIRECTION_NONE;
            case consts.TARGET_SPIN:
                return this.internalState.spinVector.direction === consts.DIRECTION_NONE;
            case consts.TARGET_NONE:
                return this.internalState.rotationVector.direction !== consts.DIRECTION_NONE ||
                       this.internalState.inclinationVector.direction !== consts.DIRECTION_NONE ||
                       this.internalState.spinVector.direction !== consts.DIRECTION_NONE;
            default:
                alert("Something went wonky.");
        }
    }

    photoTaken = () => {
        if (this.storedOffset < 0) {
            this.storedOffset = this.getGammaCompensatedMagneticNorth(this.lastData);
        }
        this.nextDestination();
        if (this.internalState.currentDestinationIndex < this.internalState.totalDestinations){
            this.calculate(this.lastData);
        }

        return this.lastData.do;
    }

    getCurrentDestination() {
        if (this.internalState.currentDestinationIndex < this.internalState.totalDestinations &&
            this.internalState.currentDestinationIndex > -1) {
            return this.destinations[this.internalState.currentDestinationIndex];
        } else {
            return null;
        }
    }

    nextDestination() {
        this.internalState.currentDestinationIndex++;
        this.updateCurrentTargetIndex();
    }
}

export default VectorCalculator;